.heroImageSpotlight {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color:rgba(254, 176, 59, 0.6);
  filter: blur(40px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media screen(md) {
    height: 280px;
    width: 280px;
  }

  @media screen(lg) {
    height: 320px;
    width: 320px;
  }

  @media screen(xl) {
    height: 360px;
    width: 360px;
  }
}