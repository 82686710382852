@import './tailwind.css';

body {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.width-wrapper {
  max-width: 1330px;
  width: 92%;
  margin: auto;
}
